@use "../_variable/variable" as *;
@use "break-point" as *;
//	utility
//---------------------------------------------------

@mixin clearfix(){
	&::after{
		display: block;
		content: "";
		clear: both;
	}
}

@mixin l-cont{
	@include m-a;
	width: 86%;
	max-width: $main_w;
}

//	dafault_parts
//---------------------------------------------------
@mixin m-a {
	margin-left: auto;
	margin-right:auto;
}

@mixin dec-line {
	text-decoration: underline !important;
}
@mixin dec-none {
	text-decoration: none !important;
}
