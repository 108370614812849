@use "../_mixin/break-point" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	feed
/--------------------------------------------------------------------*/
.feed-flex {
	@include media(tb) {
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;
	}
	@include media(pc) {
		&:before{
			content:"";
			display: block;
			width: 23%;
			order:1;
		}
		&:after{
			content:"";
			display: block;
			width: 23%;
		}
	}
}

.feed-box {
	margin-bottom: 6rem;
	@include media(tb) {
		width: 48%;
	}
	@include media(pc) {
		width: 23%;
	}

	a {
		position: relative;
		padding-bottom: 30px;
		display: block;
		text-decoration: none;
		color: $txt_c;
		letter-spacing: 1px;
		height: 100%;
	}
	figure {
		position: relative;
		margin-bottom: 15px;
		overflow: hidden;
		background: none;
		max-height: 200px;

		&:after {
			position: absolute;
			bottom: 12px;
			right: 0;
			transition: 0.3s ease all;
			content: "MORE";
			display: block;
			width: 100px;
			line-height: 1;
			padding: 8px 0;
			text-align: center;
			background-color: $white;
			font-size: 13px;
			font-family: 'Roboto Condensed', sans-serif;
			z-index: 10;
		}

		img {
			margin: 0px auto;
			width: 100%;
			min-height: 150px;
			max-height: 200px;
			@include media(pc_s) {
				min-height: 200px;
			}
		}
	}
	&:hover figure img {
		transform: scale(1.1, 1.1);
		transition: all 0.3s ease 0s;
	}
	h2 {
		margin-bottom: 1rem;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: normal;
		line-height: 1.5;
		@include media(pc) {
			font-size: 15px;
		}
	}
	h3 {
		margin-bottom: 1rem;
		color: $d-gray;
		font-weight: 400;
		font-size: 15px;
	}
	p {
		font-size: 14px;
		line-height: 1.5;
		&.date-time {
			position: absolute;
			bottom: 0;
			right: 0;
			padding-top: 5px;
			line-height: 1;
			letter-spacing: 0;
			text-align: center;
			font-size: 12px;
			width: 136px;
			height: 26px;
			border: 1px solid $l-gray;
			color: #9c9c9c;
		}
	}
	&:hover figure:after {
		background: $b-gray;
		color: $white;
	}
}

.feed-box_img {
	margin-bottom: 1.5rem;
	position: relative;
	width: 100%;
	overflow: hidden;
	&:before {
		display: block;
		position: relative;
		padding-top: 65%;
		content: "";
	}
	picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/*--------------------------------------------------------------------/
	cat-list
/--------------------------------------------------------------------*/
.cat-list {
	position: relative;

	span {
		position: relative;
		display: inline-block;
		padding: 8px 8px 0;
		min-width: 50%;
		height: 30px;
		background: $b-gray;
		color: #fff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 1px;
		line-height: 1;
		text-align: center;
		z-index: 20;
	}
}

.cat-list_link {
	margin-bottom: 3.8rem;
	position: relative;
//	@include media(pc_s) {
//		&:before {
//			position: absolute;
//			top: 5px;
//			left: 0;
//			font-size: 16px;
//			font-family: 'Roboto Condensed', sans-serif;
//			content: "CATEGORY";
//		}
//	}
	& > li {
		margin-bottom: 5px;
		margin-right: 4px;
		display: inline-block;
		min-width: 156px;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 1px;
		line-height: 1;
		background: $b-gray;
		text-align: center;
		box-sizing: border-box;
		@include media(pc-s_less) {
			min-width: 60px;
			font-size: 11px;
		}
		a {
			display: block;
			padding: 10px 10px;
			color: #fff;
			text-decoration: none;
			@include media(tb_less) {
				padding: 8px 13px;
			}
		}
	}
}

.cat-list_detail {
	position: relative;

	& > li {
		margin-bottom: 5px;
		margin-right: 4px;
		display: inline-block;
		min-width: 156px;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 1px;
		line-height: 1;
		background: $b-gray;
		text-align: center;
		box-sizing: border-box;
		padding: 10px 13px;
		color: #fff;
		@include media(pc-s_less) {
			min-width: 60px;
			font-size: 11px;
		}
	}
}
.cat-all {
	a {
		padding: 9px 10px !important;
		border: 1px solid $brown;
		background: #fff;
		color: $black !important;
		@include media(tb_less) {
			padding: 7px 10px !important;
		}
	}
}