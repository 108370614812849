//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #d82f4b;//サイトメインカラー
$sub_c:     #1e2148;//サイトサブカラー
$point_c:   #304a52;//ポイントカラー
$txt_c:     #0e0e0e;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// hover
$main_ch:   #22223a;//サイトメインカラー ホバー時
$red_h:     #d82f4b;

// mono tone
$white:     #fff;   //ホワイト
$o-white:   #f5f5f4;//オフホワイト
$p-gray:    #f0f0f0;   //ペールグレー
$l-gray:    #dddddd;   //ライトグレー
$m-gray:    #E5E9EC;   //グレー
$gray:      #cccccc;   //グレー
$dl-gray:   #aaa;   //ダルグレー
$d-gray:    #666;   //ダークグレー
$b-gray:    #222;   //ブラックグレー
$black:     #000;   //ブラック
$beige:     #e7e4e1;

// color tone
$red:       #c90436;//レッド
$orange:    #f5a52b;//オレンジ
$yellow:    #eee188;//イエロー
$y-green:   #8ec320;//イエローグリーン
$green:     #209728;//グリーン
$l-blue:    #ecf1f9;//ライトブルー
$s-blue:    #7CBFDA;//スカイブルー
$blue:      #2b91c1;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #811967;//パープル
$pink:      #ee7289;//ピンク
$l-pink:    #faf2f7;//ピンク
$brown:     #4c463f;//ブラウン

// 明度調整
$palettes: (
	main_c: (
		base:   $main_c,
		light:  lighten($main_c, 10%),
		dark:   darken($main_c, 10%)
		),
	sub_c: (
		base:   $sub_c,
		light:  lighten($sub_c, 10%),
		dark:   darken($sub_c, 10%)
		),
	point_c: (
		base:   $point_c,
		light:  lighten($point_c, 10%),
		dark:   darken($point_c, 10%)
		),
	grey: (
		base:  $gray,
		light: lighten($gray, 10%),
		dark:  darken($gray, 10%)
		)
	);
@function palettes($key, $tone: base) {
	@return map-get( map-get($palettes, $key), $tone);
}