@use "../_variable/variable" as *;

@mixin font($fontname){
	$name : map-get($set-g-font, $fontname);
	font-family:#{$name};
}

@mixin fz($size: 10) {
	font-size: round-decimal(calc($size / 10),2) + rem;
}

@mixin fz_vw($size:14,$size2:20,$vp:tab){
	@if $vp == tab{
		$vw:$sm;
		$vw-sa:nounit($lg - $sm);
	}
	@if $vp == sp{
		$vw:$sm;
		$vw-sa:nounit($md - $sm);
	}
	$fz_s: round-decimal(calc($size / 14),2) + rem;
	$sa: $size2 - $size;
	$sa_ie: $sa + px;
}


//font-weight
@mixin f-w($weight:400){
	font-weight: $weight;
}

//letter-spacing
@mixin l-sp($l-sp:1px) {
	letter-spacing: $l-sp;
}
//line-height
@mixin line-h($height:1.75) {
	line-height: $height;
}

//font-family
@mixin set_font-family {
	font-family: 'Noto Sans JP', 'Hiragino Sans',"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック","Yu Gothic","游ゴシック体",YuGothicM,YuGothic,"メイリオ", Meiryo, sans-serif;
}
@mixin font_oswald{
	font-family: 'Oswald', sans-serif;
}


//font-size
$f-pc:16;
$f-tb:15;
$f-sp:15;
@mixin f-base{
	font-size:#{$f-pc}px;
}

@mixin f-em($em:16) {
	font-size: floor-decimal(calc($em / $f-pc) * 1em);
}

@mixin f-size($rem) {
	font-size: $rem;
}
@mixin f-s_sp($size,$wid) {
	font-size:#{$size}rem + calc(#{$wid} * (100vw - 320px) / #{$pc_size - $sp_size});
}
@mixin f-s_tb($size,$wid) {
	font-size:#{$size}rem + calc(#{$wid} * (100vw - #{$sp}) / #{$pc_size - $sp_size});
}
@mixin f-s_pc($size,$wid) {
	font-size:#{$size}rem + calc(#{$wid} * (100vw - #{$tb}) / #{$pc_size - $tb_size});
}

@mixin rem-pad($top:0, $right: 0, $bottom: 0, $left:0) {
	padding:calc(($top / 16) * 1rem) calc(($right / 16) * 1rem) calc(($bottom / 16) * 1rem) calc(($left / 16) * 1rem);
}

@mixin rem-mar($top:0, $right: 0, $bottom: 0, $left:0) {
	margin:calc(($top / 16) * 1rem) calc(($right / 16) * 1rem) calc(($bottom / 16) * 1rem) calc(($left / 16) * 1rem);
}

