@charset "UTF-8";
@media screen and (min-width: 1400px) {
  /*====================================================================/
  /*-------------------full PCスタイル-------------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  /*====================================================================/
  /*-------------------PCスタイル-------------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 961px) {
  /*====================================================================/
  /*-------------------小さいPCスタイル----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 768px) {
  /*====================================================================/
  /*------------------タブレットスタイル----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 481px) {
  /*====================================================================/
  /*------------------スマホ 以上----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 375px) {
  /*====================================================================/
  /*------------------iPhone 以上----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  /*====================================================================/
  /*------------------PC以下---------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  /*====================================================================/
  /*------------------スマホタブレット共通スタイル---------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  /*====================================================================/
  /*------------------スマホのみスタイル----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 374px) {
  /*====================================================================/
  /*------------------iPhone 以下----------------
  /====================================================================*/
  html {
    width: 100%;
  }
}
/*--------------------------------------------------------------------/
	feed
/--------------------------------------------------------------------*/
@media print, screen and (min-width: 768px) {
  .feed-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1100px) {
  .feed-flex:before {
    content: "";
    display: block;
    width: 23%;
    order: 1;
  }
  .feed-flex:after {
    content: "";
    display: block;
    width: 23%;
  }
}

.feed-box {
  margin-bottom: 6rem;
}
@media print, screen and (min-width: 768px) {
  .feed-box {
    width: 48%;
  }
}
@media screen and (min-width: 1100px) {
  .feed-box {
    width: 23%;
  }
}
.feed-box a {
  position: relative;
  padding-bottom: 30px;
  display: block;
  text-decoration: none;
  color: #0e0e0e;
  letter-spacing: 1px;
  height: 100%;
}
.feed-box figure {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  background: none;
  max-height: 200px;
}
.feed-box figure:after {
  position: absolute;
  bottom: 12px;
  right: 0;
  transition: 0.3s ease all;
  content: "MORE";
  display: block;
  width: 100px;
  line-height: 1;
  padding: 8px 0;
  text-align: center;
  background-color: #fff;
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 10;
}
.feed-box figure img {
  margin: 0px auto;
  width: 100%;
  min-height: 150px;
  max-height: 200px;
}
@media print, screen and (min-width: 961px) {
  .feed-box figure img {
    min-height: 200px;
  }
}
.feed-box:hover figure img {
  transform: scale(1.1, 1.1);
  transition: all 0.3s ease 0s;
}
.feed-box h2 {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
}
@media screen and (min-width: 1100px) {
  .feed-box h2 {
    font-size: 15px;
  }
}
.feed-box h3 {
  margin-bottom: 1rem;
  color: #666;
  font-weight: 400;
  font-size: 15px;
}
.feed-box p {
  font-size: 14px;
  line-height: 1.5;
}
.feed-box p.date-time {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-top: 5px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  font-size: 12px;
  width: 136px;
  height: 26px;
  border: 1px solid #dddddd;
  color: #9c9c9c;
}
.feed-box:hover figure:after {
  background: #222;
  color: #fff;
}

.feed-box_img {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.feed-box_img:before {
  display: block;
  position: relative;
  padding-top: 65%;
  content: "";
}
.feed-box_img picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------------/
	cat-list
/--------------------------------------------------------------------*/
.cat-list {
  position: relative;
}
.cat-list span {
  position: relative;
  display: inline-block;
  padding: 8px 8px 0;
  min-width: 50%;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
  z-index: 20;
}

.cat-list_link {
  margin-bottom: 3.8rem;
  position: relative;
}
.cat-list_link > li {
  margin-bottom: 5px;
  margin-right: 4px;
  display: inline-block;
  min-width: 156px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  background: #222;
  text-align: center;
  box-sizing: border-box;
}
@media screen and (max-width: 960px) {
  .cat-list_link > li {
    min-width: 60px;
    font-size: 11px;
  }
}
.cat-list_link > li a {
  display: block;
  padding: 10px 10px;
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .cat-list_link > li a {
    padding: 8px 13px;
  }
}

.cat-list_detail {
  position: relative;
}
.cat-list_detail > li {
  margin-bottom: 5px;
  margin-right: 4px;
  display: inline-block;
  min-width: 156px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  background: #222;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 13px;
  color: #fff;
}
@media screen and (max-width: 960px) {
  .cat-list_detail > li {
    min-width: 60px;
    font-size: 11px;
  }
}

.cat-all a {
  padding: 9px 10px !important;
  border: 1px solid #4c463f;
  background: #fff;
  color: #000 !important;
}
@media screen and (max-width: 767px) {
  .cat-all a {
    padding: 7px 10px !important;
  }
}

/*--------------------------------------------------------------------/
	main_bg
/--------------------------------------------------------------------*/
.main_bg {
  background: url(../img/common/bg.gif);
  background-attachment: fixed;
}

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
.map {
  margin-bottom: 1rem;
}
.map iframe {
  width: 100%;
  height: 280px;
}
@media print, screen and (min-width: 768px) {
  .map iframe {
    height: 500px;
  }
}

/*--------------------------------------------------------------------/
	iv-search
/--------------------------------------------------------------------*/
#ivsearch input, #ivsearch select {
  margin: 0;
  display: block;
  width: 100%;
  padding: 7px;
  letter-spacing: 1px;
  line-height: normal;
  font-family: "Noto Sans JP", sans-serif;
  background: #fff;
}
#ivsearch input.submit {
  display: block;
  padding: 7px;
  width: 100%;
  text-align: center;
  background: #000;
  color: #fff;
  border: none;
  line-height: normal;
  cursor: pointer;
}
#ivsearch input.submit:hover {
  background: #333;
}

.iv-search {
  margin-bottom: 4rem;
  padding: 1.5rem 0;
  background: #f5f5f4;
}
@media print, screen and (min-width: 768px) {
  .iv-search_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.iv-search .search-box {
  margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 768px) {
  .iv-search .search-box {
    width: 33%;
  }
}
@media screen and (min-width: 1100px) {
  .iv-search .search-box {
    margin-bottom: 0;
    width: 25%;
  }
}
.iv-search .search-btn {
  width: 100%;
}
@media screen and (min-width: 1100px) {
  .iv-search .search-btn {
    width: 22%;
  }
}

.sp-search {
  padding: 1.5rem;
}
@media print, screen and (min-width: 768px) {
  .sp-search .search-box {
    margin-bottom: 0;
    width: 38%;
  }
}
@media screen and (min-width: 1100px) {
  .sp-search .search-box {
    width: 38%;
  }
}
.sp-search .search-btn {
  width: 100%;
}
@media print, screen and (min-width: 768px) {
  .sp-search .search-btn {
    width: 20%;
  }
}

/*--------------------------------------------------------------------/
	feed(news)
/--------------------------------------------------------------------*/
.news-list {
  position: relative;
  margin-bottom: 5rem;
}
@media print, screen and (min-width: 768px) {
  .news-list {
    margin-bottom: 2rem;
  }
}
.news-list .new-mark {
  display: block;
  position: absolute;
  top: -8px;
  right: 15px;
  padding: 4px 0;
  width: 60px;
  text-align: center;
  background: #d82f4b;
  color: #fff;
  font-size: 12px;
}
.news-list::after {
  transition: 0.3s ease all;
}
@media screen and (max-width: 767px) {
  .news-list::after {
    position: absolute;
    bottom: -25px;
    left: 0;
    display: block;
    width: 100%;
    height: 25px;
    padding: 3px 0 0;
    line-height: normal;
    background: #dddddd;
    color: #000;
    font-size: 12px;
    text-align: center;
    content: "MORE";
  }
}
@media print, screen and (min-width: 768px) {
  .news-list::after {
    position: absolute;
    top: calc(50% - 1rem);
    right: 1.5rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    font-size: 2rem;
    color: #cccccc;
  }
}
@media print, screen and (min-width: 768px) {
  .news-list:hover::after {
    color: #d82f4b;
  }
}
.news-list a {
  position: relative;
  display: block;
  border: 1px solid #dddddd;
  padding: 1rem;
  text-decoration: none;
  color: #0e0e0e;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media print, screen and (min-width: 768px) {
  .news-list a {
    padding: 0;
  }
}
.news-list a:hover {
  border: 1px solid #d82f4b;
}
.news-list figure {
  width: 26%;
  height: 17vw;
}
@media print, screen and (min-width: 768px) {
  .news-list figure {
    width: 200px;
    height: 133px;
  }
}
.news-list figure img {
  width: 100%;
  height: 100%;
}
.news-list_txt {
  width: 70%;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 768px) {
  .news-list_txt {
    padding: 2rem;
    width: 70%;
  }
}
@media print, screen and (min-width: 961px) {
  .news-list_txt {
    width: 75%;
  }
}
@media screen and (min-width: 1100px) {
  .news-list_txt {
    width: 78%;
  }
}
@media screen and (min-width: 1400px) {
  .news-list_txt {
    width: 82%;
    padding: 2rem 3rem 2rem 0;
  }
}
.news-list_cat {
  margin-bottom: 10px;
  font-size: 13px;
}
@media print, screen and (min-width: 768px) {
  .news-list_cat {
    margin-bottom: 10px;
  }
}
.news-list_cat span {
  display: inline-block;
  padding: 3px 10px;
  min-width: 80px;
  text-align: center;
  line-height: normal;
  padding: 3px 10px;
  background: #f5f5f4;
}
.news-list_tit {
  margin-bottom: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1.4rem;
}
@media print, screen and (min-width: 768px) {
  .news-list_tit {
    font-weight: 600;
    font-size: 1.5rem;
  }
}
.news-list .date-time {
  position: absolute;
  bottom: 10px;
  right: 15px;
  text-align: right;
  line-height: 1;
  letter-spacing: 0;
  font-size: 12px;
  color: #666;
}
@media print, screen and (min-width: 768px) {
  .news-list .date-time {
    bottom: 1.5rem;
    right: 3rem;
  }
}

.news-txt .addtoany_share_save_container {
  margin-top: 4rem;
  padding-top: 3rem;
  border-top: 1px solid #dddddd;
}

/*--------------------------------------------------------------------/
	詳細
--------------------------------------------------------------------*/
.cont-detail .info-box {
  position: relative;
  margin-bottom: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #dddddd;
}
.cont-detail .info-box .date-time {
  position: absolute;
  top: 10px;
  right: 0;
}
.cont-detail .info-box .cat-list_link {
  margin-bottom: 0;
}
.cont-detail .date-time {
  padding: 5px 13px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  font-size: 12px;
  border: 1px solid #dddddd;
  color: #9c9c9c;
}
.cont-detail .cf-txt {
  padding: 2rem;
  background-color: #f0f0f0;
}
@media screen and (min-width: 1100px) {
  .cont-detail .cf-txt {
    padding: 3rem;
  }
}
.cont-detail .cf-pdf {
  margin-top: 4rem;
}
.cont-detail .cf-pdf .pdf-icon {
  width: 100%;
}
.cont-detail .cf-pdf .pdf-icon a {
  display: block;
  padding: 2rem;
  position: relative;
  background: #222;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 1.6rem;
  color: #fff;
}
@media print, screen and (min-width: 768px) {
  .cont-detail .cf-pdf .pdf-icon a {
    font-size: 1.8rem;
    font-weight: 600;
  }
}
.cont-detail .cf-pdf .pdf-icon a::after {
  transition: 0.3s ease all;
  position: absolute;
  top: calc(50% - 6px);
  right: 11px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  font-size: 12px;
}
.cont-detail .cf-pdf .pdf-icon a .fa-file-pdf {
  padding-right: 1rem;
  font-size: 140%;
  font-weight: 400;
}
.cont-detail .cf-pdf .pdf-icon a:hover {
  background: #1e2148;
}
.cont-detail .cf-pdf .pdf-icon a:hover::after {
  right: 8px;
}
.cont-detail .cf-video {
  margin-top: 7rem;
}
.cont-detail .cf-video_txt {
  margin-top: 1.5rem;
}
@media print, screen and (min-width: 961px) {
  .cont-detail .cf-video_txt {
    margin-top: 0;
    float: right;
    width: 50%;
  }
}
.cont-detail .video-mvbox {
  margin-bottom: 1rem;
}
@media print, screen and (min-width: 961px) {
  .cont-detail .video-mvbox {
    margin-bottom: 0;
    float: left;
    width: 45%;
  }
}
.cont-detail .video-mv {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.cont-detail .video-mv iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
.cont-detail .comment {
  margin-top: 8rem;
  border-top: 1px dashed #aaa;
  padding-top: 7rem;
}
.cont-detail .comment-box {
  border-bottom: 1px dashed #aaa;
  margin-bottom: 5.5rem;
  padding-bottom: 4rem;
}
@media screen and (min-width: 1100px) {
  .cont-detail .comment-box {
    margin-bottom: 7rem;
    padding-bottom: 7rem;
  }
}
.cont-detail .comment-box_txt h4 {
  margin-bottom: 3rem;
  letter-spacing: 1px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #993300;
}
@media print, screen and (min-width: 768px) {
  .cont-detail .comment-box_txt h4 {
    font-size: 2.2rem;
  }
}
.cont-detail .comment-box_txt h5 {
  position: relative;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .cont-detail .comment-box_txt h5 {
    font-size: 1.7rem;
  }
}
.cont-detail .comment-box_txt h5::before {
  content: "━";
  padding-right: 10px;
}
.cont-detail .comment-box_txt p {
  line-height: 2;
}
.cont-detail .comment-box figure {
  margin-bottom: 4rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail .comment-box figure {
    float: right;
    width: 42%;
    margin-left: 7%;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail .comment-box figure {
    margin-bottom: 5rem;
  }
}
.cont-detail .comment-box figure img {
  border-radius: 3px;
}
@media print, screen and (min-width: 768px) {
  .cont-detail .comment-box:nth-of-type(odd) figure {
    float: left;
    margin-left: 0;
    margin-right: 7%;
  }
}
.cont-detail_img {
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_img {
    float: right;
    width: 36%;
    margin-left: 3rem;
  }
}
.cont-detail .cf-bnr {
  padding-top: 50px;
}
.cont-detail_backbtn {
  margin-top: 4rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.cf-table {
  margin-top: 5rem;
}

.normal-table {
  margin: 0 auto 3rem;
  width: 100%;
}
@media print, screen and (min-width: 768px) {
  .normal-table {
    border-top: 1px solid #dddddd;
    display: table;
  }
}
.normal-table th, .normal-table td {
  display: block;
  width: 100%;
  padding: 1.2rem 1.5rem;
  text-align: left;
  line-height: 1.6;
  font-size: 15px;
  word-break: break-all;
}
@media print, screen and (min-width: 768px) {
  .normal-table th, .normal-table td {
    vertical-align: middle;
    display: table-cell;
    border-bottom: 1px solid #dddddd;
  }
}
.normal-table th {
  font-weight: 500;
  background: #f5f5f4;
}
@media print, screen and (min-width: 768px) {
  .normal-table th {
    background: none;
    padding: 1.6rem 1.5rem;
    width: 25%;
  }
}
@media print, screen and (min-width: 961px) {
  .normal-table th {
    width: 20%;
  }
}
@media print, screen and (min-width: 768px) {
  .normal-table td {
    width: auto;
  }
}
.normal-table td p {
  margin: 0;
}

.cf-map {
  margin-top: 8rem;
  margin-bottom: 5rem;
}
@media print, screen and (min-width: 961px) {
  .cf-map {
    margin-top: 10rem;
  }
}

/*--------------------------------------------------------------------/
	新宿御苑特集
/--------------------------------------------------------------------*/
.cont-special_tit {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
  padding: 5rem 0;
  width: 100%;
  max-width: 1400px;
  background: url(../img/special/tit-bg_sp.jpg) no-repeat 50% 0;
  background-size: cover;
}
@media print, screen and (min-width: 768px) {
  .cont-special_tit {
    background: url(../img/special/tit-bg_tb.jpg) no-repeat 50% 0;
    background-size: cover;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-special_tit {
    padding: 6rem 0;
    background: url(../img/special/tit-bg.jpg) no-repeat 50% 0;
    background-size: cover;
    min-height: 550px;
  }
}
.cont-special_tit h1 {
  margin: 0px auto 3rem;
  width: 50%;
  max-width: 270px;
}
@media screen and (min-width: 1100px) {
  .cont-special_tit h1 {
    max-width: 375px;
  }
}
.cont-special_tit_txt {
  margin: 0px auto;
  padding: 35px 9%;
  width: 90%;
  max-width: 685px;
  background: rgba(0, 0, 0, 0.8);
}
@media print, screen and (min-width: 768px) {
  .cont-special_tit_txt {
    padding: 50px 6%;
  }
}
.cont-special_tit_txt h2 {
  margin-bottom: 2rem;
  color: #fff;
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
}
@media print, screen and (min-width: 768px) {
  .cont-special_tit_txt h2 {
    font-size: 2.3rem;
  }
}
.cont-special_tit_txt p {
  color: #fff;
  letter-spacing: 1px;
  line-height: 2;
  font-size: 14px;
}
@media print, screen and (min-width: 768px) {
  .cont-special_tit_txt p {
    font-size: 15px;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-special_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-special_flex:after {
    content: "";
    display: block;
    width: 31%;
  }
}
@media screen and (min-width: 1400px) {
  .cont-special_flex:before {
    content: "";
    display: block;
    width: 23%;
    order: 1;
  }
  .cont-special_flex:after {
    content: "";
    display: block;
    width: 23%;
  }
}
.cont-special_list {
  margin-bottom: 6rem;
}
@media print, screen and (min-width: 768px) {
  .cont-special_list {
    width: 48%;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-special_list {
    width: 31%;
  }
}
@media screen and (min-width: 1400px) {
  .cont-special_list {
    width: 23%;
  }
}
.cont-special_list a {
  position: relative;
  display: block;
  text-decoration: none;
  color: #0e0e0e;
  letter-spacing: 1px;
  height: 100%;
  padding-bottom: 45px;
}
.cont-special_list .txt h2 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.5;
}
@media screen and (min-width: 1100px) {
  .cont-special_list .txt h2 {
    font-size: 16px;
  }
}
.cont-special_list .txt p {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}
.cont-special_list figure {
  position: relative;
  overflow: hidden;
  max-height: 240px;
}
.cont-special_list figure img {
  margin: 0px auto;
  width: 100%;
  min-height: 180px;
  max-height: 240px;
}
@media print, screen and (min-width: 961px) {
  .cont-special_list figure img {
    min-height: 240px;
  }
}
.cont-special_list .cat-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px dashed #cccccc;
  padding-top: 1.3rem;
}
.cont-special_list .more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: #f0f0f0;
  color: #000;
  font-size: 14px;
  text-align: center;
  transition: 0.3s;
}
.cont-special_list:hover figure img {
  transform: scale(1.1, 1.1);
  transition: all 0.3s ease 0s;
}
.cont-special_list:hover .cat-list span {
  background: #d82f4b;
  transition: all 0.3s ease 0s;
}

.cont-detail_special h2 {
  margin-bottom: 1rem;
  font-size: 1.7rem;
  letter-spacing: 1px;
  line-height: 1.3;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special h2 {
    font-size: 2rem;
  }
}
.cont-detail_special .special-top {
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top {
    margin-bottom: 5rem;
    background: #d82f4b;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top {
    background: none;
    flex-direction: row-reverse;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top figure {
    width: 50%;
    padding: 2rem;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special .special-top figure {
    padding: 0;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top figure {
    width: 60%;
  }
}
.cont-detail_special .special-top figure img {
  border-radius: 3px;
  width: 100%;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top_txt {
    padding: 4rem 5%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top_txt {
    position: absolute;
    top: calc(50% - 150px);
    left: 0;
    padding: 3rem 4% 1rem;
    width: 50%;
    background-color: rgba(216, 47, 75, 0.9);
    border: 10px double #fff;
    min-height: 280px;
  }
}
.cont-detail_special .special-top_txt h3 {
  letter-spacing: 1px;
  font-weight: 400;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top_txt h3 {
    margin-bottom: 2rem;
    color: #fff;
    font-size: 2rem;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special .special-top_txt h3 {
    font-size: 2.3rem;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top_txt h3 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .cont-detail_special .special-top_txt h3 {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background: #d82f4b;
    color: #fff;
    font-size: 1.8rem;
  }
}
.cont-detail_special .special-top_txt p {
  font-size: 14px;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top_txt p {
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top_txt p {
    font-size: 1.7rem;
  }
}
.cont-detail_special .special-top_txt p span {
  display: block;
  font-size: 13px;
  color: #666;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .special-top_txt p span {
    color: rgba(255, 255, 255, 0.7);
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .special-top_txt p span {
    font-size: 1.7rem;
  }
}
.cont-detail_special .prof {
  margin-top: 4rem;
  border: 10px solid #f0f0f0;
  padding: 2rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof {
    padding: 3rem;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special .prof {
    padding: 4rem 5rem;
  }
}
.cont-detail_special .prof h2 {
  margin-bottom: 1rem;
  color: #aaa;
  letter-spacing: 1px;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof h2 {
    text-align: left;
    font-size: 4rem;
  }
}
.cont-detail_special .prof h3 {
  margin-bottom: 2.5rem;
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof h3 {
    text-align: left;
  }
}
.cont-detail_special .prof h3 span {
  display: block;
  margin-bottom: 5px;
  font-size: 1.5rem;
}
.cont-detail_special .prof figure {
  margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof figure {
    float: right;
    width: 35%;
  }
}
.cont-detail_special .prof figure img {
  width: 100%;
  max-width: 250px;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof figure img {
    max-width: none;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special .prof-txt {
    float: left;
    width: 60%;
  }
}
.cont-detail_special .sns {
  margin-top: 3rem;
}

/*--------------------------------------------------------------------/
	special_interview
/--------------------------------------------------------------------*/
@media print, screen and (min-width: 768px) {
  .cont-special_interview_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-special_interview_flex:after {
    content: "";
    display: block;
    width: 31%;
  }
}
@media screen and (min-width: 1400px) {
  .cont-special_interview_flex:before {
    content: "";
    display: block;
    width: 23%;
    order: 1;
  }
  .cont-special_interview_flex:after {
    content: "";
    display: block;
    width: 23%;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-special_interview_flex .interview-list_box {
    width: 48%;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-special_interview_flex .interview-list_box {
    width: 31%;
  }
}
@media screen and (min-width: 1400px) {
  .cont-special_interview_flex .interview-list_box {
    width: 23%;
  }
}

.cont-detail_special_interview .special_interview-top {
  position: relative;
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special_interview .special_interview-top {
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special_interview .special_interview-top figure {
    width: 50%;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .special_interview-top figure {
    width: 55%;
  }
}
.cont-detail_special_interview .special_interview-top figure img {
  border-radius: 3px;
  width: 100%;
}
.cont-detail_special_interview .special_interview-top_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special_interview .special_interview-top_txt {
    min-height: 280px;
    width: 50%;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .special_interview-top_txt {
    width: 45%;
  }
}
.cont-detail_special_interview .special_interview-top_txt h2 {
  letter-spacing: 2px;
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .special_interview-top_txt h2 {
    font-size: 2rem;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special_interview .special_interview-top_txt h2 {
    margin-bottom: 3.5rem;
    padding-left: 3rem;
    letter-spacing: 0.15em;
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .special_interview-top_txt h2 {
    padding-left: 4rem;
  }
}
@media screen and (min-width: 1400px) {
  .cont-detail_special_interview .special_interview-top_txt h2 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 960px) {
  .cont-detail_special_interview .special_interview-top_txt h2 {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #dddddd;
    font-size: 1.6rem;
    text-align: center;
    order: 2;
  }
}
.cont-detail_special_interview .special_interview-top_txt p {
  margin-bottom: 2.5rem;
  font-weight: 700;
  font-size: 15px;
}
@media print, screen and (min-width: 961px) {
  .cont-detail_special_interview .special_interview-top_txt p {
    margin-bottom: 0;
    padding: 3rem;
    font-size: 1.7rem;
    background-color: #E5E9EC;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .special_interview-top_txt p {
    padding: 3rem 4rem;
  }
}
@media screen and (max-width: 960px) {
  .cont-detail_special_interview .special_interview-top_txt p {
    text-align: center;
    order: 3;
  }
}
.cont-detail_special_interview .special_interview-top_txt p span {
  font-weight: 400;
  display: block;
  font-size: 13px;
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .special_interview-top_txt p span {
    font-size: 1.7rem;
  }
}
.cont-detail_special_interview .special_interview-top_img {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .cont-detail_special_interview .special_interview-top_img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 60%;
    max-width: 300px;
    order: 1;
  }
}
.cont-detail_special_interview .cf-txt {
  margin-top: 3rem;
  margin-bottom: 5rem;
  padding: 3rem 3rem;
  background-color: #fff;
  border: 1px dotted #aaa;
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .cf-txt {
    padding: 3.5rem 5%;
  }
}
.cont-detail_special_interview .cf-txt p {
  margin-bottom: 0;
}
.cont-detail_special_interview .prof {
  margin-top: 4rem;
  border: 10px solid #f0f0f0;
  padding: 2rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof {
    padding: 3rem;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_special_interview .prof {
    padding: 4rem 5rem;
  }
}
.cont-detail_special_interview .prof h2 {
  margin-bottom: 1rem;
  color: #aaa;
  letter-spacing: 1px;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof h2 {
    text-align: left;
    font-size: 4rem;
  }
}
.cont-detail_special_interview .prof h3 {
  margin-bottom: 2.5rem;
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof h3 {
    text-align: left;
  }
}
.cont-detail_special_interview .prof h3 span {
  display: block;
  margin-bottom: 5px;
  font-size: 1.5rem;
}
.cont-detail_special_interview .prof figure {
  margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof figure {
    float: right;
    width: 35%;
  }
}
.cont-detail_special_interview .prof figure img {
  width: 100%;
  max-width: 250px;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof figure img {
    max-width: none;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_special_interview .prof-txt {
    float: left;
    width: 60%;
  }
}
.cont-detail_special_interview .sns {
  margin-top: 3rem;
}

.interview-comment-nav {
  position: relative;
  margin-top: clamp(5rem, 8vw, 8rem);
  margin-bottom: clamp(5rem, 12vw, 12rem);
  background-color: #f5f5f4;
}
.interview-comment-nav__inner {
  padding: 0 0 4rem;
  position: relative;
}
.interview-comment-nav__tit {
  position: absolute;
  top: -15px;
  left: 3%;
  letter-spacing: 0;
  font-weight: 700;
  position: relative;
  color: #d82f4b;
  font-size: 1.5em;
  font-family: "Roboto Condensed", sans-serif;
}
@media print, screen and (min-width: 961px) {
  .interview-comment-nav__tit {
    top: -20px;
    font-size: 2.2em;
  }
}
.interview-comment-nav__list {
  margin-left: auto;
  margin-right: auto;
  width: 92%;
  counter-reset: interview-comment-navnum;
}

.interview-comment-nav__item {
  border-bottom: 1px dashed #aaa;
}
.interview-comment-nav__item a {
  display: block;
  transition: 0.3s ease all;
  color: #0e0e0e;
  position: relative;
  text-decoration: none;
  padding: 1.15em 5rem 1.15em 0;
}
@media print, screen and (min-width: 768px) {
  .interview-comment-nav__item a {
    padding: 1.25em 6.5rem 1.25em 0;
  }
}
@media print, screen and (min-width: 961px) {
  .interview-comment-nav__item a {
    padding: 1.5em 6rem 1.5em 0;
  }
}
.interview-comment-nav__item a:hover {
  background: #fff;
  padding-left: 1em;
}
.interview-comment-nav__item__tit {
  padding-left: 3em;
  position: relative;
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: 0.08em;
}
@media print, screen and (min-width: 768px) {
  .interview-comment-nav__item__tit {
    font-size: 1.04em;
  }
}
@media screen and (min-width: 1100px) {
  .interview-comment-nav__item__tit {
    font-size: 20px;
  }
}
.interview-comment-nav__item__tit::before {
  position: absolute;
  top: 50%;
  left: 0;
  color: #d82f4b;
  transition: 0.3s ease all;
  transform: translateY(-50%);
  counter-increment: interview-comment-navnum;
  content: counter(interview-comment-navnum, decimal-leading-zero);
  line-height: 1;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.7em;
  font-weight: 700;
}
.interview-comment-nav__item__arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: 0.3s ease all;
  background: #d82f4b;
}
.interview-comment-nav__item__arrow i {
  position: relative;
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
}
.interview-comment-nav__item__arrow i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media print, screen and (min-width: 768px) {
  .interview-comment-nav__item__arrow i::before {
    font-size: 18px;
  }
}

.interview-comment {
  margin-top: 8rem;
  border-top: 1px dashed #aaa;
  padding-top: 7rem;
  counter-reset: free01-num;
}
.interview-comment_box {
  border-bottom: 1px dashed #aaa;
  margin-bottom: 5.5rem;
  padding-bottom: 4rem;
}
@media screen and (min-width: 1100px) {
  .interview-comment_box {
    margin-bottom: 7rem;
    padding-top: 2rem;
    padding-bottom: 7rem;
  }
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_flex {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_flex:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
}
.interview-comment_box_tit {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #d82f4b;
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_tit {
    min-height: 64px;
  }
}
.interview-comment_box_tit h4 {
  position: relative;
  letter-spacing: 1px;
  padding-left: 50px;
  font-size: 1.8rem;
  font-weight: 600;
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_tit h4 {
    padding-left: 60px;
    font-size: 1.8rem;
    min-height: 60px;
  }
}
@media screen and (min-width: 1100px) {
  .interview-comment_box_tit h4 {
    font-size: 2.4rem;
    min-height: 70px;
    padding-left: 94px;
    display: flex;
    align-items: center;
  }
}
.interview-comment_box_tit h4::before {
  position: absolute;
  top: 0;
  left: 0;
  counter-increment: free01-num;
  content: counter(free01-num, decimal-leading-zero);
  color: #d82f4b;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}
@media screen and (min-width: 1100px) {
  .interview-comment_box_tit h4::before {
    font-size: 70px;
    top: auto;
  }
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_txt {
    width: 57%;
  }
}
.interview-comment_box_txt h5 {
  position: relative;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_txt h5 {
    font-size: 1.7rem;
  }
}
.interview-comment_box_txt h5::before {
  content: "━";
  padding-right: 10px;
}
.interview-comment_box_txt p {
  line-height: 2;
}
.interview-comment_box_img {
  margin-bottom: 2.5rem;
}
@media print, screen and (min-width: 768px) {
  .interview-comment_box_img {
    width: 38%;
  }
}
.interview-comment_box_img figure {
  padding: 14px;
  border: 1px solid #E5E9EC;
  border-radius: 3px;
}
@media screen and (min-width: 1100px) {
  .interview-comment_box_img figure {
    margin-bottom: 5rem;
  }
}
.interview-comment_box_img figure img {
  border-radius: 3px;
  width: 100%;
}

/*--------------------------------------------------------------------/
	interview
/--------------------------------------------------------------------*/
@media print, screen and (min-width: 768px) {
  .interview-list_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 961px) {
  .interview-list_flex:after {
    content: "";
    display: block;
    width: 30%;
  }
}
.interview-list_flex .interview-list_box {
  margin-bottom: 6rem;
}
@media print, screen and (min-width: 768px) {
  .interview-list_flex .interview-list_box {
    width: 48%;
  }
}
@media print, screen and (min-width: 961px) {
  .interview-list_flex .interview-list_box {
    width: 30%;
  }
}
.interview-list .btn__wrap {
  width: 100%;
  max-width: 300px;
}

.interview-list_box {
  margin-bottom: 6rem;
}
.interview-list_box a {
  padding-bottom: 25px;
  position: relative;
  display: block;
  text-decoration: none;
  color: #0e0e0e;
  letter-spacing: 1px;
  height: 100%;
}
.interview-list_box a:hover picture img {
  transform: scale(1.1, 1.1);
  transition: all 0.3s ease 0s;
}
.interview-list_box h2 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
}
@media screen and (min-width: 1100px) {
  .interview-list_box h2 {
    font-size: 17px;
  }
}
.interview-list_box h3 {
  margin-bottom: 1rem;
  color: #666;
  font-weight: 500;
  font-size: 15px;
}
.interview-list_box p {
  font-size: 14px;
  line-height: 1.5;
}
.interview-list_box p.date-time {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-top: 5px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  font-size: 12px;
  width: 136px;
  height: 26px;
  border: 1px solid #dddddd;
  color: #9c9c9c;
}
.interview-list_box .cat {
  border-top: 1px dashed #dddddd;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #2b91c1;
}
.interview-list_box .cat span {
  padding-right: 10px;
}
.interview-list_box .place {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  text-align: center;
  border-radius: 5px;
  background: #666;
}
.interview-list_box .place p {
  margin: 0;
  color: #fff;
}
.interview-list_box .day {
  position: absolute;
  bottom: 0;
  right: 0;
}
.interview-list_box .day p {
  margin: 0;
}

.interview-detail .ttl {
  margin-bottom: 4rem;
  position: relative;
}
.interview-detail .ttl-box {
  text-align: center;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl-box {
    text-align: left;
    position: absolute;
    top: 4.5rem;
    left: 0;
    padding: 4rem;
    width: 700px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
}
.interview-detail .ttl-box h2 {
  margin-bottom: 2rem;
  border-bottom: 1px dotted #aaa;
  padding-bottom: 1rem;
  letter-spacing: 1px;
  line-height: 1.6;
  font-size: 1.7rem;
  font-weight: 400;
  text-align: center;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl-box h2 {
    margin-bottom: 3rem;
    border-bottom: none;
    letter-spacing: 2px;
    padding-bottom: 0;
    text-align: left;
    font-size: 2rem;
  }
}
.interview-detail .ttl-box h3 {
  line-height: 1.4;
  letter-spacing: 2px;
  font-size: 1.7rem;
  text-align: center;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl-box h3 {
    margin-bottom: 5px;
    font-family: "Noto Serif JP", serif;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
  }
}
.interview-detail .ttl-box p {
  margin-bottom: 0;
  letter-spacing: 2px;
  padding-bottom: 0;
  color: #666;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl-box p {
    font-family: "Noto Serif JP", serif;
    color: #ddd;
  }
}
.interview-detail .ttl-box .cat-name {
  margin-bottom: 1.5rem;
  font-size: 0.85em;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl-box .cat-name {
    margin-bottom: 0;
  }
}
.interview-detail .ttl-box .cat-name span {
  display: inline-block;
}
.interview-detail .ttl-box .cat-name span:not(:last-child)::after {
  content: "/";
  padding-left: 0.3rem;
  margin-right: 0.3rem;
}
@media screen and (min-width: 1100px) {
  .interview-detail .ttl figure {
    margin-left: 500px;
  }
}
.interview-detail .ttl figure img {
  width: 100%;
  max-width: 800px;
}
.interview-detail .main_txt {
  margin-bottom: 5rem;
  padding: 3.5rem;
  border: 1px dotted #aaa;
}
.interview-detail .main_txt p {
  margin-bottom: 0;
}
.interview-detail .comment-box {
  padding-bottom: 4rem;
}
.interview-detail .comment-box:not(:last-child) {
  margin-bottom: 5rem;
  border-bottom: 1px solid #dddddd;
}
.interview-detail .comment-box figure {
  margin-bottom: 2.5rem;
}
.interview-detail .comment-box figure img {
  margin-top: 0.8rem;
  padding: 1px;
  border: 12px solid #e7e4e1;
}
.interview-detail .comment-box .txt-tit {
  font-size: 1.6rem;
  color: #993300;
  font-weight: normal;
  line-height: 1.9;
  padding-bottom: 1.7rem;
}
@media screen and (min-width: 1100px) {
  .interview-detail .comment-box .txt-tit {
    font-size: 1.9rem;
  }
}
.interview-detail .comment-box .question {
  font-weight: 700;
  padding-bottom: 1.7rem;
  line-height: 1.9;
}
.interview-detail .comment-box p {
  line-height: 1.9;
}
@media screen and (min-width: 1100px) {
  .interview-detail .comment-box p {
    line-height: 2.2;
  }
}
.interview-detail .prof {
  margin-bottom: 4rem;
  border-top: 1px dotted #aaa;
  padding-top: 3rem;
}
.interview-detail .prof h2 {
  margin-bottom: 1rem;
  color: #aaa;
  letter-spacing: 1px;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .interview-detail .prof h2 {
    text-align: left;
  }
}
.interview-detail .prof h3 {
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 1.9rem;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .interview-detail .prof h3 {
    text-align: left;
  }
}
.interview-detail .prof h3 span {
  display: block;
  font-size: 1.4rem;
}
.interview-detail .prof figure {
  margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 768px) {
  .interview-detail .prof figure {
    float: left;
    width: 35%;
  }
}
@media print, screen and (min-width: 768px) {
  .interview-detail .prof-txt {
    float: right;
    width: 62%;
  }
}
.interview-detail .prof .up-date {
  color: #666;
}
.interview-detail .btn__wrap {
  width: 100%;
  max-width: 300px;
}

/*--------------------------------------------------------------------/
	interviewer
/--------------------------------------------------------------------*/
.cont-interviewer .feed-box {
  border: 1px solid #dddddd;
}
.cont-interviewer .feed-box a {
  padding: 0 0 1rem;
}
.cont-interviewer .feed-box a:hover {
  background: #d82f4b;
  border: none;
  color: #fff;
}
.cont-interviewer .feed-box .tit {
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1;
}
.cont-interviewer .feed-box figure:after {
  color: #fff;
  background-color: #000;
}
.cont-interviewer .feed-box:hover figure:after {
  background: #fff;
  color: #000;
}

.cont-detail_interviewer {
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer {
    padding: 4rem 4rem 2rem;
    border: 15px solid #f0f0f0;
  }
}
.cont-detail_interviewer:after {
  position: absolute;
  top: -26px;
  right: 0;
  content: "PROFILE";
  width: 100px;
  line-height: 1;
  height: 26px;
  color: #fff;
  text-align: center;
  background-color: #d82f4b;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer:after {
    font-size: 15px;
    top: -34px;
    width: 140px;
    height: 34px;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-box figure {
    float: left;
    width: 38%;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-box_txt {
    float: right;
    width: 57%;
  }
}
.cont-detail_interviewer .interviewer-box_txt h2 {
  padding: 2rem 0;
  text-align: center;
  font-size: 2.3rem;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-box_txt h2 {
    padding: 0 0 2rem;
    text-align: left;
  }
}
@media screen and (min-width: 1100px) {
  .cont-detail_interviewer .interviewer-box_txt h2 {
    padding: 1rem 0 2rem;
  }
}
.cont-detail_interviewer .interviewer-table {
  margin: 4rem auto 0;
  width: 100%;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-table {
    border-top: 1px solid #dddddd;
    display: table;
  }
}
.cont-detail_interviewer .interviewer-table th, .cont-detail_interviewer .interviewer-table td {
  display: block;
  width: 100%;
  padding: 1.2rem 1.5rem;
  text-align: left;
  line-height: 1.6;
  font-size: 15px;
  word-break: break-all;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-table th, .cont-detail_interviewer .interviewer-table td {
    vertical-align: middle;
    display: table-cell;
    border-bottom: 1px solid #dddddd;
  }
}
.cont-detail_interviewer .interviewer-table th {
  font-weight: 500;
  background: #f5f5f4;
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-table th {
    background: none;
    padding: 1.6rem 0;
    width: 25%;
  }
}
@media print, screen and (min-width: 961px) {
  .cont-detail_interviewer .interviewer-table th {
    width: 20%;
  }
}
@media print, screen and (min-width: 768px) {
  .cont-detail_interviewer .interviewer-table td {
    width: auto;
  }
}
.cont-detail_interviewer .sns-add {
  margin-top: 2rem;
}