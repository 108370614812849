@use '../_mixin/break-point' as *;
@include media(pc_l){
	/*====================================================================/
	/*-------------------full PCスタイル-------------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	
	@include media(pc){
	/*====================================================================/
	/*-------------------PCスタイル-------------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(pc_s){
	/*====================================================================/
	/*-------------------小さいPCスタイル----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(tb){
	/*====================================================================/
	/*------------------タブレットスタイル----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(sp){
	/*====================================================================/
	/*------------------スマホ 以上----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(sp_s){
	/*====================================================================/
	/*------------------iPhone 以上----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(pc_less){
	/*====================================================================/
	/*------------------PC以下---------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	
	@include media(tb_less){
	/*====================================================================/
	/*------------------スマホタブレット共通スタイル---------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	
	@include media(sp_less){
	/*====================================================================/
	/*------------------スマホのみスタイル----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	@include media(sp_s_less){
	/*====================================================================/
	/*------------------iPhone 以下----------------
	/====================================================================*/
	html{
		width: 100%;
	}
	}
	