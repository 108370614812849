@charset "utf-8";
@use "_mixin/break-point" as *;
@use "_base" as *;
@use "_component/feed"; //feed
/*--------------------------------------------------------------------/
	main_bg
/--------------------------------------------------------------------*/
.main_bg {
	background: url(../img/common/bg.gif);
	background-attachment: fixed;
}

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
.map {
	margin-bottom: 1rem;
	iframe {
		width: 100%;
		height: 280px;
		@include media(tb) {
			height: 500px;
		}
	}
}

/*--------------------------------------------------------------------/
	iv-search
/--------------------------------------------------------------------*/
#ivsearch {
	input,select {
		margin: 0;
		display: block;
		width: 100%;
		padding: 7px;
		letter-spacing: 1px;
		line-height: normal;
		font-family: 'Noto Sans JP', sans-serif;
		background: $white;
	}
	input.submit {
		display: block;
		padding: 7px;
		width: 100%;
		text-align: center;
		background: #000;
		color: #fff;
		border: none;
		line-height: normal;
		cursor: pointer;

		&:hover {
			background: #333;
		}
	}
}

.iv-search {
	margin-bottom: 4rem;
	padding: 1.5rem 0;
	background: $o-white;

	&_flex {
		@include media(tb) {
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
		}
	}

	.search-box {
		margin-bottom: 1.5rem;
		@include media(tb) {
			width: 33%;
		}
		@include media(pc) {
			margin-bottom: 0;
			width: 25%;
		}
	}
	.search-btn {
		width: 100%;
		@include media(pc) {
			width: 22%;
		}
	}
}

.sp-search {
	padding: 1.5rem;

	.search-box {
		@include media(tb) {
			margin-bottom: 0;
			width: 38%;
		}
		@include media(pc) {
			width: 38%;
		}
	}
	.search-btn {
		width: 100%;
		@include media(tb) {
			width: 20%;
		}
	}
}

/*--------------------------------------------------------------------/
	feed(news)
/--------------------------------------------------------------------*/


.news-list {
	position: relative;
	margin-bottom: 5rem;
	@include media(tb) {
		margin-bottom: 2rem;
	}
	.new-mark {
		display: block;
		position: absolute;
		top: -8px;
		right: 15px;
		padding: 4px 0;
		width: 60px;
		text-align: center;
		background: $main_c;
		color: $white;
		font-size: 12px;
	}

	&::after {
		transition: 0.3s ease all;
		@include media(tb_less) {
			position: absolute;
			bottom: -25px;
			left: 0;
			display: block;
			width: 100%;
			height: 25px;
			padding: 3px 0 0;
			line-height: normal;
			background: $l-gray;
			color: $black;
			font-size: 12px;
			text-align: center;
			content: "MORE";
		}
		@include media(tb) {
			position: absolute;
			top: calc(50% - 1rem);
			right: 1.5rem;
			font-family:'Font Awesome 5 Free';
			font-weight: 900;
			content: '\f054';
			font-size: 2rem;
			color: $gray;
		}
	}
	&:hover::after {
		@include media(tb) {
			color: $main_c;
		}
	}

	a {
		position: relative;
		display: block;
		border: 1px solid $l-gray;
		padding: 1rem;
		text-decoration: none;
		color: $txt_c;
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;
		@include media(tb) {
			padding: 0;
		}

		&:hover {
			border: 1px solid $main_c;
		}
	}

	figure {
		width: 26%;
		height: 17vw;
		@include media(tb) {
			width: 200px;
			height: 133px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}
	&_txt {
		width: 70%;
		padding-bottom: 15px;
		@include media(tb) {
			padding: 2rem;
			width: 70%;
		}
		@include media(pc_s) {
			width: 75%;
		}
		@include media(pc) {
			width: 78%;
		}
		@include media(pc_l) {
			width: 82%;
			padding: 2rem 3rem 2rem 0;
		}
	}
	&_cat {
		margin-bottom: 10px;
		font-size: 13px;
		@include media(tb){
			margin-bottom: 10px;
		}
		span {
			display: inline-block;
			padding: 3px 10px;
			min-width: 80px;
			text-align: center;
			line-height: normal;
			padding: 3px 10px;
			background: $o-white;
		}
	}

	&_tit {
		margin-bottom: 1rem;
		font-weight: 400;
		letter-spacing: 1px;
		font-size: 1.4rem;
		@include media(tb) {
			font-weight: 600;
			font-size: 1.5rem;
		}
	}
	.date-time {
		position: absolute;
		bottom: 10px;
		right: 15px;
		text-align: right;
		line-height: 1;
		letter-spacing: 0;
		font-size: 12px;
		color: $d-gray;
		@include media(tb) {
			bottom: 1.5rem;
			right: 3rem;
		}
	}
}

.news-txt {
	.addtoany_share_save_container {
		margin-top: 4rem;
		padding-top: 3rem;
		border-top: 1px solid $l-gray;
	}
}

/*--------------------------------------------------------------------/
	詳細
--------------------------------------------------------------------*/
.cont-detail {

	.info-box {
		position: relative;
		margin-bottom: 3rem;
		padding-top: 1rem;
		border-top: 1px solid $l-gray;

		.date-time {
			position: absolute;
			top: 10px;
			right: 0;
		}

		.cat-list_link {
			margin-bottom: 0;
		}
	}

	.date-time {
		padding: 5px 13px;
		line-height: 1;
		letter-spacing: 0;
		text-align: center;
		font-size: 12px;
		border: 1px solid $l-gray;
		color: #9c9c9c;
	}

	.cf-txt {
		padding: 2rem;
		background-color: $p-gray;
		@include media(pc){
			padding: 3rem;
		}
	}

	.cf-pdf {
		margin-top: 4rem;
		.pdf-icon {
			width: 100%;
	
			a {
				display: block;
				padding: 2rem;
				position: relative;
				background: $b-gray;
				text-decoration: none;
				letter-spacing: 1px;
				font-size: 1.6rem;
				color: $white;
				@include media(tb){
					font-size: 1.8rem;
					font-weight: 600;
					}

				&::after {
					transition: 0.3s ease all;
					position: absolute;
					top: calc(50% - 6px);
					right: 11px;
					font-family:'Font Awesome 5 Free';
					font-weight: 900;
					content: '\f054';
					font-size: 12px;
				}

				.fa-file-pdf {
					padding-right: 1rem;
					font-size: 140%;
					font-weight: 400;
				}

				&:hover {
					background: $sub-c;
					&::after {
						right: 8px;
					}
				}
			}
		}
	}
	
	.cf-video {
		margin-top: 7rem;

		&_txt {
			margin-top: 1.5rem;
			@include media(pc_s){
				margin-top: 0;
				float: right;
				width: 50%;
			}
		}
	}
	.video-mvbox {
		margin-bottom: 1rem;
		@include media(pc_s){
			margin-bottom: 0;
			float: left;
			width: 45%;
		}
	}
	.video-mv {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
	}
	.video-mv iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important
	}

	.comment {
		margin-top: 8rem;
		border-top: 1px dashed $dl-gray;
		padding-top: 7rem;
	}
	.comment-box {
		border-bottom: 1px dashed $dl-gray;
		margin-bottom: 5.5rem;
		padding-bottom: 4rem;
		@include media(pc){
			margin-bottom: 7rem;
			padding-bottom: 7rem;
		}

		&_txt {
			h4 {
				margin-bottom: 3rem;
				letter-spacing: 1px;
				font-size: 1.8rem;
				font-weight: 400;
				color: #993300;
				@include media(tb){
					font-size: 2.2rem;
				}
			}
			h5 {
				position: relative;
				margin-bottom: 2.5rem;
				letter-spacing: 1px;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 1.5;
				@include media(tb){
					font-size: 1.7rem;
				}
				&::before {
					content: "━";
					padding-right: 10px;
				}
			}
			p {
				line-height: 2;
			}
		}

		figure{
			margin-bottom: 4rem;
			@include media(tb){
				float: right;
				width: 42%;
				margin-left: 7%;
			}
			@include media(pc){
				margin-bottom: 5rem;
			}
			img {
				border-radius: 3px;
			}
		}
		&:nth-of-type(odd) figure {
			@include media(tb){
				float: left;
				margin-left: 0;
				margin-right: 7%;
			}
		}
	}

	&_img{
		margin-bottom: 2rem;
		@include media(tb) {
			float: right;
			width: 36%;
			margin-left: 3rem;
		}
	}

	.cf-bnr {
		padding-top: 50px;
	}

	&_backbtn {
		margin-top: 4rem;
		margin-bottom: 3rem;
		margin-left: auto;
		margin-right: auto;
		max-width: 300px;
	}

}

.cf-table {
	margin-top: 5rem;
}
.normal-table {
	margin: 0 auto 3rem;
	width: 100%;
	@include media(tb) {
		border-top:1px solid $l-gray;
		display: table;
	}

	th, td {
		display: block;
		width: 100%;
		padding: 1.2rem 1.5rem;
		text-align: left;
		line-height: 1.6;
		font-size: 15px;
		word-break: break-all;
		@include media(tb) {
			vertical-align: middle;
			display: table-cell;
			border-bottom:1px solid $l-gray;
		}
	}
	th {
		font-weight: 500;
		background: $o-white;
		@include media(tb) {
			background: none;
			padding: 1.6rem 1.5rem;
			width: 25%;
		}
		@include media(pc_s) {
			width: 20%;
		}
	}
	td {
		@include media(tb) {
			width: auto;
		}
		p {
			margin: 0;
		}
	}
}
.cf-map {
	margin-top: 8rem;
	margin-bottom: 5rem;
	@include media(pc_s) {
		margin-top: 10rem;
	}
}


/*--------------------------------------------------------------------/
	新宿御苑特集
/--------------------------------------------------------------------*/
.cont-special {
	&_tit {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 6rem;
		padding: 5rem 0;
		width: 100%;
		max-width: 1400px;
		background: url(../img/special/tit-bg_sp.jpg) no-repeat 50% 0;
		background-size: cover;
		@include media(tb) {
			background: url(../img/special/tit-bg_tb.jpg) no-repeat 50% 0;
			background-size: cover;
		}
		@include media(pc_s) {
			padding: 6rem 0;
			background: url(../img/special/tit-bg.jpg) no-repeat 50% 0;
			background-size: cover;
			min-height: 550px;
		}
		h1 {
			margin: 0px auto 3rem;
			width: 50%;
			max-width: 270px;
			@include media(pc) {
				max-width: 375px;
			}
		}
		&_txt {
			margin: 0px auto;
			padding: 35px 9%;
			width: 90%;
			max-width: 685px;
			background: rgba($color: #000000, $alpha: 0.8);
			@include media(tb) {
				padding: 50px 6%;
			}

			h2 {
				margin-bottom: 2rem;
				color: $white;
				letter-spacing: 1px;
				line-height: 1.5;
				text-align: center;
				font-size: 1.8rem;
				font-weight: 400;
				@include media(tb) {
					font-size: 2.3rem;
				}
			}
			
			p {
				color: $white;
				letter-spacing: 1px;
				line-height: 2;
				font-size: 14px;
				@include media(tb) {
					font-size: 15px;
				}
			}
		}
	}


	&_flex {
		@include media(tb) {
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
		}
		@include media(pc_s) {
			&:after{
				content:"";
				display: block;
				width: 31%;
			}
		}
		@include media(pc_l) {
			&:before{
				content:"";
				display: block;
				width: 23%;
				order:1;
			}
			&:after{
				content:"";
				display: block;
				width: 23%;
			}
		}
	}
	&_list {
		margin-bottom: 6rem;
		@include media(tb) {
			width: 48%;
		}
		@include media(pc_s) {
			width: 31%;
		}
		@include media(pc_l) {
			width: 23%;
		}
		a {
			position: relative;
			display: block;
			text-decoration: none;
			color: $txt_c;
			letter-spacing: 1px;
			height: 100%;
			padding-bottom: 45px;
		}
		.txt {
			h2 {
				margin-top: 15px;
				margin-bottom: 5px;
				font-weight: 700;
				font-size: 15px;
				line-height: 1.5;
				@include media(pc) {
					font-size: 16px;
				}
			}
			p {
				font-size: 14px;
				line-height: 1.5;
				color: $d-gray;
			}
		}
		figure {
			position: relative;
			overflow: hidden;
			max-height: 240px;

			img {
				margin: 0px auto;
				width: 100%;
				min-height: 180px;
				max-height: 240px;
				@include media(pc_s) {
					min-height: 240px;
				}
			}
		}
		.cat-list {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			border-top: 1px dashed $gray;
			padding-top: 1.3rem;
		}
		.more {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 10px 0;
			background: $p-gray;
			color: $black;
			font-size: 14px;
			text-align: center;
			transition: .3s;
		}
		&:hover {
			figure img {
				transform: scale(1.1, 1.1);
				transition: all 0.3s ease 0s;
			}
			.cat-list span {
				background: $main_c;
				transition: all 0.3s ease 0s;
			}
		}
	}
}

.cont-detail_special {
	h2 {
		margin-bottom: 1rem;
		font-size: 1.7rem;
		letter-spacing: 1px;
		line-height: 1.3;
		@include media(tb) {
			font-size: 2rem;
		}
	}

	.special-top {
		position: relative;
		@include media(tb) {
			margin-bottom: 5rem;
			background: $main_c;
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
		}
		@include media(pc) {
			background: none;
			flex-direction: row-reverse;
		}
		figure {
			@include media(tb) {
				width: 50%;
				padding: 2rem;
			}
			@include media(pc_s) {
				padding: 0;
			}
			@include media(pc) {
				width: 60%;
			}
			img {
				border-radius: 3px;
				width: 100%;
			}
		}

		&_txt {
			@include media(tb) {
				padding: 4rem 5%;
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			@include media(pc) {
				position: absolute;
				top: calc(50% - 150px);
				left: 0;
				padding: 3rem 4% 1rem;
				width: 50%;
				background-color: rgba($color: $main_c, $alpha: 0.9);
				border: 10px double $white;
				min-height: 280px;
			}
	
			h3 {
				letter-spacing: 1px;
				font-weight: 400;
				@include media(tb) {
					margin-bottom: 2rem;
					color: $white;
					font-size: 2rem;
				}
				@include media(pc_s) {
					font-size: 2.3rem;
				}
				@include media(pc) {
					font-size: 2.5rem;
				}

				@include media(tb_less) {
					margin-bottom: 1.5rem;
					padding: 1.5rem;
					background: $main_c;
					color: $white;
					font-size: 1.8rem;
				}
			}
			p {
				font-size: 14px;
				text-align: center;
				@include media(tb) {
					text-align: left;
					color: rgba($color: $white, $alpha: 0.7);
				}
				@include media(pc) {
					font-size: 1.7rem;
				}
				span {
					display: block;
					font-size: 13px;
					color: $d-gray;
					@include media(tb) {
						color: rgba($color: $white, $alpha: 0.7);
					}
					@include media(pc) {
						font-size: 1.7rem;
					}
				}
			}
		}
	}

	.prof {
		margin-top: 4rem;
		border: 10px solid $p-gray;
		padding: 2rem;
		@include media(tb){
			padding: 3rem;
		}
		@include media(pc){
			padding: 4rem 5rem;
		}

		h2 {
			margin-bottom: 1rem;
			color:#aaa;
			letter-spacing: 1px;
			font-size: 3rem;
			font-weight: 500;
			text-align: center;
			@include media(tb){
				text-align: left;
				font-size: 4rem;
			}
		}
		h3 {
			margin-bottom: 2.5rem;
			font-weight: 600;
			font-size: 1.8rem;
			text-align: center;
			@include media(tb){
				text-align: left;
			}
			span{
				display: block;
				margin-bottom: 5px;
				font-size: 1.5rem;
			}
		}
		figure {
			margin-bottom: 1.5rem;
			@include media(tb){
				float: right;
				width: 35%;
			}
			img {
				width: 100%;
				max-width: 250px;
				@include media(tb){
					max-width: none;
				}
			}
		}
		&-txt {
			@include media(tb){
				float: left;
				width: 60%;
			}
		}
	}

	.sns {
		margin-top: 3rem;
	}
}

/*--------------------------------------------------------------------/
	special_interview
/--------------------------------------------------------------------*/
.cont-special_interview_flex {
	@include media(tb) {
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;
	}
	@include media(pc_s) {
		&:after{
			content:"";
			display: block;
			width: 31%;
		}
	}
	@include media(pc_l) {
		&:before{
			content:"";
			display: block;
			width: 23%;
			order:1;
		}
		&:after{
			content:"";
			display: block;
			width: 23%;
		}
	}
	.interview-list_box {
		@include media(tb) {
			width: 48%;
		}
		@include media(pc_s) {
			width: 31%;
		}
		@include media(pc_l) {
			width: 23%;
		}
	}
}

.cont-detail_special_interview {
	.special_interview-top {
		position: relative;
		@include media(pc_s) {
			margin-bottom: 5rem;
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
		}
		@include media(pc) {
		}
		figure {
			@include media(pc_s) {
				width: 50%;
			}
			@include media(pc) {
				width: 55%;
			}
			img {
				border-radius: 3px;
				width: 100%;
			}
		}

		&_txt {
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include media(pc_s) {
				min-height: 280px;
				width: 50%;
			}
			@include media(pc) {
				width: 45%;
			}
			h2 {
				letter-spacing: 2px;
				font-weight: 700;
				@include media(tb) {
					font-size: 2rem;
				}
				@include media(pc_s) {
					margin-bottom: 3.5rem;
					padding-left: 3rem;
					letter-spacing: 0.15em;
					font-size: 2.1rem;
				}
				@include media(pc) {
					padding-left: 4rem;
				}
				@include media(pc_l) {
					font-size: 2.5rem;
				}
				@include media(pc-s_less) {
					margin-bottom: 1.5rem;
					padding-bottom: 1.5rem;
					border-bottom: 1px solid $l-gray;
					font-size: 1.6rem;
					text-align: center;
					order: 2;
				}
			}
			p {
				margin-bottom: 2.5rem;
				font-weight: 700;
				font-size: 15px;
				@include media(pc_s) {
					margin-bottom: 0;
					padding: 3rem;
					font-size: 1.7rem;
					background-color: $m-gray;
				}
				@include media(pc) {
					padding: 3rem 4rem;
				}
				@include media(pc-s_less) {
					text-align: center;
					order: 3;
				}
				span {
					font-weight: 400;
					display: block;
					font-size: 13px;
					@include media(pc) {
						font-size: 1.7rem;
					}
				}
			}
		}
		&_img {
			width: 100%;
			@include media(pc-s_less) {
				@include m-a;
				margin-bottom: 1rem;
				width: 60%;
				max-width: 300px;
				order: 1;
			}
		}
	}

	.cf-txt {
		margin-top: 3rem;
		margin-bottom: 5rem;
		padding: 3rem 3rem;
		background-color: $white;
		border: 1px dotted #aaa;
		@include media(pc) {
			padding: 3.5rem 5%;
		}
		p {
			margin-bottom: 0;
		}
	}

	.prof {
		margin-top: 4rem;
		border: 10px solid $p-gray;
		padding: 2rem;
		@include media(tb){
			padding: 3rem;
		}
		@include media(pc){
			padding: 4rem 5rem;
		}
		h2 {
			margin-bottom: 1rem;
			color:#aaa;
			letter-spacing: 1px;
			font-size: 3rem;
			font-weight: 500;
			text-align: center;
			@include media(tb){
				text-align: left;
				font-size: 4rem;
			}
		}
		h3 {
			margin-bottom: 2.5rem;
			font-weight: 600;
			font-size: 1.8rem;
			text-align: center;
			@include media(tb){
				text-align: left;
			}
			span{
				display: block;
				margin-bottom: 5px;
				font-size: 1.5rem;
			}
		}
		figure {
			margin-bottom: 1.5rem;
			@include media(tb){
				float: right;
				width: 35%;
			}
			img {
				width: 100%;
				max-width: 250px;
				@include media(tb){
					max-width: none;
				}
			}
		}
		&-txt {
			@include media(tb){
				float: left;
				width: 60%;
			}
		}
	}
	.sns {
		margin-top: 3rem;
	}
}

.interview-comment-nav {
	position: relative;
	margin-top: clamp(5rem, 8vw, 8rem);
	margin-bottom: clamp(5rem, 12vw, 12rem);
	background-color: $o-white;
	&__inner {
		padding: 0 0 4rem;
		position: relative;
	}
	&__tit {
		position: absolute;
		top: -15px;
		left: 3%;
		@include l-sp(0);
		@include f-w(700);
		position: relative;
		color: $main_c;
		font-size: 1.5em;
		font-family: 'Roboto Condensed', sans-serif;
		@include media(pc_s){
			top: -20px;
			font-size: 2.2em;
		}
	}
	&__list {
		@include m-a;
		width: 92%;
		counter-reset: interview-comment-navnum;
	}
}
.interview-comment-nav__item {
	border-bottom: 1px dashed $dl-gray;
	a {
		display: block;
		@include transition;
		color: $txt_c;
		position: relative;
		text-decoration: none;
		padding: 1.15em 5rem 1.15em 0;
		@include media(tb){
			padding: 1.25em 6.5rem 1.25em 0;
		}
		@include media(pc_s){
			padding: 1.5em 6rem 1.5em 0;
		}
		&:hover {
			background: $white;
			padding-left: 1em;
		}
	}
	&__tit {
		padding-left: 3em;
		position: relative;
		@include f-w(600);
		font-size: 0.85em;
		letter-spacing: 0.08em;
		@include media(tb){
			font-size: 1.04em;
		}
		@include media(pc){
			font-size: 20px;
		}
		&::before {
			position: absolute;
			top: 50%;
			left: 0;
			color: $main_c;
			@include transition;
			transform: translateY(-50%);
			counter-increment: interview-comment-navnum;
			content: counter(interview-comment-navnum, decimal-leading-zero);
			@include line-h(1);
			font-family: 'Roboto Condensed', sans-serif;
			font-size: 1.7em;
			@include f-w(700);
		}
	}
	&__arrow {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 30px;
		height: 30px;
		@include transition;
		background: $main_c;
		i {
			position: relative;
			color: $white;
			display: block;
			width: 100%;
			height: 100%;
			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include media(tb){
					font-size: 18px;
				}
			}
		}
	}
}

.interview-comment {
	margin-top: 8rem;
	border-top: 1px dashed $dl-gray;
	padding-top: 7rem;
	counter-reset: free01-num;
	&_box {
		border-bottom: 1px dashed $dl-gray;
		margin-bottom: 5.5rem;
		padding-bottom: 4rem;
		@include media(pc){
			margin-bottom: 7rem;
			padding-top: 2rem;
			padding-bottom: 7rem;
		}
		&_flex {
			@include media(tb){
				display: flex;
				justify-content: space-between;
			}
			&:nth-of-type(odd) {
				@include media(tb){
					flex-direction: row-reverse;
				}
			}
		}
		&_tit {
			margin-bottom: 3rem;
			padding-bottom: 2rem;
			border-bottom: 1px solid $main_c;
			@include media(tb){
				min-height: 64px;
			}
			h4 {
				position: relative;
				letter-spacing: 1px;
				padding-left: 50px;
				font-size: 1.8rem;
				font-weight: 600;
				@include media(tb){
					padding-left: 60px;
					font-size: 1.8rem;
					min-height: 60px;
				}
				@include media(pc){
					font-size: 2.4rem;
					min-height: 70px;
					padding-left: 94px;
					display: flex;
					align-items: center;
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					counter-increment: free01-num;
					content: counter(free01-num, decimal-leading-zero);
					color: $main_c;
					font-family: 'Roboto Condensed', sans-serif;
					font-size: 40px;
					font-weight: 700;
					line-height: 1;
					@include media(pc){
						font-size: 70px;
						top: auto;
					}
				}
			}
		}
		&_txt {
			@include media(tb){
				width: 57%;
			}
			h5 {
				position: relative;
				margin-bottom: 2.5rem;
				letter-spacing: 1px;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 1.5;
				@include media(tb){
					font-size: 1.7rem;
				}
				&::before {
					content: "━";
					padding-right: 10px;
				}
			}
			p {
				line-height: 2;
			}
		}
	
		&_img {
			margin-bottom: 2.5rem;
			@include media(tb){
				width: 38%;
			}
			figure{
				padding: 14px;
				border: 1px solid $m-gray;
				border-radius: 3px;
				@include media(pc){
					margin-bottom: 5rem;
				}
				img {
					border-radius: 3px;
					width: 100%;
				}
			}
		}
	}
}


/*--------------------------------------------------------------------/
	interview
/--------------------------------------------------------------------*/
.interview-list {
	&_flex {
		@include media(tb) {
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
		}
		@include media(pc_s) {
			&:after{
				content:"";
				display: block;
				width: 30%;
			}
		}
		.interview-list_box {
			margin-bottom: 6rem;
			@include media(tb) {
				width: 48%;
			}
			@include media(pc_s) {
				width: 30%;
			}
		}
	}
	.btn__wrap {
		width: 100%;
		max-width: 300px;
	}
}

.interview-list_box {
	margin-bottom: 6rem;
	a {
		padding-bottom: 25px;
		position: relative;
		display: block;
		text-decoration: none;
		color: $txt_c;
		letter-spacing: 1px;
		height: 100%;
		&:hover picture img {
			transform: scale(1.1, 1.1);
			transition: all 0.3s ease 0s;
		}
	}
	h2 {
		margin-bottom: 5px;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: normal;
		line-height: 1.5;
		@include media(pc) {
			font-size: 17px;
		}
	}
	h3 {
		margin-bottom: 1rem;
		color: $d-gray;
		font-weight: 500;
		font-size: 15px;
	}
	p {
		font-size: 14px;
		line-height: 1.5;
		&.date-time {
			position: absolute;
			bottom: 0;
			right: 0;
			padding-top: 5px;
			line-height: 1;
			letter-spacing: 0;
			text-align: center;
			font-size: 12px;
			width: 136px;
			height: 26px;
			border: 1px solid $l-gray;
			color: #9c9c9c;
		}
	}
	.cat {
		border-top: 1px dashed $l-gray;
		padding-top: 10px;
		margin-bottom: 0px;
		color: $blue;
		span {
			padding-right: 10px;
		}
	}
	.place {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 40%;
		text-align: center;
		border-radius: 5px;
		background: $d-gray;
		p {
			margin: 0;
			color: $white;
		}
	}
	.day {
		position: absolute;
		bottom: 0;
		right: 0;
		p {
			margin: 0;
		}
	}
}

.interview-detail {

	.ttl {
		margin-bottom: 4rem;
		position: relative;

		&-box {
			text-align: center;
			@include media(pc){
				text-align: left;
				position: absolute;
				top: 4.5rem;
				left: 0;
				padding: 4rem;
				width: 700px;
				background-color: rgba(0,0,0,0.8);
				color: #fff;
			}

			h2 {
				margin-bottom: 2rem;
				border-bottom: 1px dotted #aaa;
				padding-bottom: 1rem;
				letter-spacing: 1px;
				line-height: 1.6;
				font-size: 1.7rem;
				font-weight: 400;
				text-align: center;
				@include media(pc){
					margin-bottom: 3rem;
					border-bottom: none;
					letter-spacing: 2px;
					padding-bottom: 0;
					text-align: left;
					font-size: 2rem;
				}
			}
			h3 {
				line-height: 1.4;
				letter-spacing: 2px;
				font-size: 1.7rem;
				text-align: center;
				@include media(pc){
					margin-bottom: 5px;
					font-family: 'Noto Serif JP', serif;
					text-align: left;
					font-size: 2rem;
					font-weight: 500;
				}
			}
			p {
				margin-bottom: 0;
				letter-spacing: 2px;
				padding-bottom:0;
				color: #666;
				@include media(pc){
					font-family: 'Noto Serif JP', serif;
					color: #ddd;
				}
			}
			.cat-name {
				margin-bottom: 1.5rem;
				font-size:0.85em;
				@include media(pc){
					margin-bottom: 0;
				}
				span {
					display:inline-block;
					&:not(:last-child)::after {
						content:"/";
						padding-left:0.3rem;
						margin-right:0.3rem;
					}
				}
			}
		}
		figure {
			@include media(pc){
				margin-left: 500px;
			}
			img {
				width: 100%;
				max-width: 800px;
			}
		}
	}

	.main_txt {
		margin-bottom: 5rem;
		padding: 3.5rem;
		border: 1px dotted #aaa;
		p {
			margin-bottom: 0;
		}
	}

	.comment {
		&-box {
			padding-bottom: 4rem;

			&:not(:last-child){
				margin-bottom: 5rem;
				border-bottom: 1px solid $l-gray;
			}

			figure {
				margin-bottom: 2.5rem;
				img {
					margin-top: .8rem;
					padding: 1px;
					border: 12px solid $beige;
				}
			}
			.txt-tit {
				font-size: 1.6rem;
				color: #993300;
				font-weight: normal;
				line-height: 1.9;
				padding-bottom: 1.7rem;
				@include media(pc){
					font-size: 1.9rem;
				}
			}
			.question {
				font-weight: 700;
				padding-bottom: 1.7rem;
				line-height: 1.9;
			}
			p {
				line-height: 1.9;
				@include media(pc){
					line-height: 2.2;
				}
			}
		}
	}

	.prof {
		margin-bottom: 4rem;
		border-top: 1px dotted #aaa;
		padding-top: 3rem;

		h2 {
			margin-bottom: 1rem;
			color:#aaa;
			letter-spacing: 1px;
			font-size: 3rem;
			font-weight: 500;
			text-align: center;
			@include media(tb){
				text-align: left;
			}
		}
		h3 {
			margin-bottom: 1.5rem;
			font-weight: 600;
			font-size: 1.9rem;
			text-align: center;
			@include media(tb){
				text-align: left;
			}
			span{
				display: block;
				font-size: 1.4rem;
			}
		}
		figure {
			margin-bottom: 1.5rem;
			@include media(tb){
				float: left;
				width: 35%;
			}
		}

		&-txt {
			@include media(tb){
				float: right;
				width: 62%;
			}
		}

		.up-date {
			color: $d-gray;
		}
	}

	.btn__wrap {
		width: 100%;
		max-width: 300px;
	}
}


/*--------------------------------------------------------------------/
	interviewer
/--------------------------------------------------------------------*/
.cont-interviewer {

	.feed-box {
			border: 1px solid $l-gray;
		a {
			padding: 0 0 1rem;
			&:hover {
				background: $main_c;
				border: none;
				color: $white;
			}
		}
		.tit {
			margin-bottom: 1rem;
			text-align: center;
			font-weight: 400;
			font-size: 1.8rem;
			line-height: 1;
		}
		figure {
			&:after {
				color: $white;
				background-color: $black;
			}
		}
		&:hover figure:after {
			background: $white;
			color: $black;
		}
	}
}
.cont-detail_interviewer {
	position: relative;
	@include media(tb) {
		padding: 4rem 4rem 2rem;
		border: 15px solid $p-gray;
	}
	&:after {
		position: absolute;
		top: -26px;
		right: 0;
		content: "PROFILE";
		width: 100px;
		line-height: 1;
		height: 26px;
		color: $white;
		text-align: center;
		background-color: $main_c;
		font-size: 13px;
		letter-spacing: 1px;
		font-family: 'Roboto Condensed', sans-serif;
		z-index: 10;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		@include media(tb) {
			font-size: 15px;
			top: -34px;
			width: 140px;
			height: 34px;
		}
	}

	.interviewer-box {
		figure {
			@include media(tb) {
				float: left;
				width: 38%;
			}
		}
		
		&_txt {
			@include media(tb) {
				float: right;
				width: 57%;
			}
	
			h2 {
				padding: 2rem 0;
				text-align: center;
				font-size: 2.3rem;
				@include media(tb) {
					padding: 0 0 2rem;
					text-align: left;
				}
				@include media(pc) {
					padding: 1rem 0 2rem;
				}
			}
		}
	}
	
	.interviewer-table {
		margin: 4rem auto 0;
		width: 100%;
		@include media(tb) {
			border-top:1px solid $l-gray;
			display: table;
		}

		th, td {
			display: block;
			width: 100%;
			padding: 1.2rem 1.5rem;
			text-align: left;
			line-height: 1.6;
			font-size: 15px;
			word-break: break-all;
			@include media(tb) {
				vertical-align: middle;
				display: table-cell;
				border-bottom:1px solid $l-gray;
			}
		}
		th {
			font-weight: 500;
			background: $o-white;
			@include media(tb) {
				background: none;
				padding: 1.6rem 0;
				width: 25%;
			}
			@include media(pc_s) {
				width: 20%;
			}
		}
		td {
			@include media(tb) {
				width: auto;
			}
		}
	}
	.sns-add {
		margin-top: 2rem;
	}
}
